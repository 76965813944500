<template>
    <v-card class="pa-4">
        <v-card-title>
            {{ title }}
            <v-spacer />
            <refresh-button v-if="onRefreshClicked" :loading="refreshing" :onClick="onRefreshClicked" />
        </v-card-title>

        <v-card-text>
            <v-simple-table>
                <template v-slot:default>
                    <thead>
                        <tr>
                            <th class="text-left">URL</th>
                            <th class="text-left">Depth</th>
                            <th class="text-left">Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-if="refreshing">
                            <td colspan="3" class="text-center">
                                <v-progress-circular indeterminate color="primary"></v-progress-circular>
                            </td>
                        </tr>
                        <tr v-for="transaction in refreshing ? [] : transactionData.transactions" :key="transaction.id" v-else>
                            <td>{{ transaction.url }}</td>
                            <td>{{ transaction.depth }}</td>
                            <td>
                                <transaction-status :transaction="transaction" />
                            </td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
            <v-pagination v-model="page"
                :length="Math.floor(transactionData.count / itemsPerPage)" @next="handleNext" @prev="handlePrev" @input="handleInput"/>
        </v-card-text>
    </v-card>
</template>

<script>
import RefreshButton from '@/components/RefreshButton.vue'
import TransactionStatus from './TransactionStatus.vue'

export default {
    components: {
        RefreshButton,
        TransactionStatus
    },

    props: [
        'title',
        'transactionData',
        'refreshing',
        'onRefreshClicked',
        'itemsPerPage',
    ],

    data: () => ({
        headers: [{
            text: 'URL',
            value: 'url'
        }, {
            text: 'Depth',
            value: 'depth'
        }, {
            text: 'Status',
            value: 'scanned'
        }],
        page: 1,
    }),
    methods: {
        handleNext() {
            if (this.page < this.itemsPerPage) {
                this.$emit('next', {page: this.page});
            }
        },
        handlePrev() {
            if (this.page > 0) {
                this.$emit('prev', {page: this.page});
            }
        },
        handleInput(page) {
            this.$emit('next', {page})
        }
    },
}
</script>
