<template>
  <v-dialog
    v-if="transaction"
    v-model="dialog"
    scrollable
    width="800"
  >
    <template v-slot:activator="{ on: dialogOn, attrs: dialogAttrs }">
      <v-tooltip
        v-bind="dialogAttrs"
        v-on="dialogOn"
        bottom
      >
        <template v-slot:activator="{ on: tooltipOn, attrs: tooltipAttrs }">
          <v-icon
            :color="color"
            v-bind="tooltipAttrs"
            v-on="transaction.error ? { click: () => dialog = true, ...tooltipOn } : tooltipOn"
          >{{icon}}</v-icon>
        </template>
        <span>{{tooltip}}</span>
      </v-tooltip>
    </template>

    <v-card>
      <v-card-title>Error Details</v-card-title>
      <v-card-text v-if="transaction.error">
        <pre>{{JSON.parse(transaction.error) | prettyJson}}</pre>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    filters: {
      prettyJson: function(value) {
        return JSON.stringify(JSON.parse(value), null, 2)
      }
    },

    props: ['transaction'],

    data: () =>  ({
      dialog: false
    }),

    computed: {
      color: function() {
        switch (true) {
          case (this.transaction.error !== undefined):
            return 'error'
          case (this.transaction.statusCode>=400):
            return 'warning'
          case (this.transaction.scanned):
            return 'success'
          default:
            return 'gray'
        }
      },
      icon: function() {
        switch (true) {
          case (this.transaction.error !== undefined):
            return 'mdi-alert-circle-outline'
          // case (this.transaction.scanned && 'accessibilityS3Key' in this.transaction):
          case (this.transaction.statusCode>=400): //trying to catch broken links
            return 'mdi-alert-remove'
          case (this.transaction.scanned):
            return 'mdi-checkbox-marked-circle-outline'
          default:
            return 'mdi-clock-outline'
        }
      },
      tooltip: function() {
        switch (true) {
          case (this.transaction.error !== undefined):
            return 'Error'
          case(this.transaction.statusCode==404):
            return ('Error 404: Page not found')
          case (this.transaction.statusCode>=400):
            return ('Error ' + this.transaction.statusCode);
          case (this.transaction.scanned && 'accessibilityS3Key' in this.transaction):
            return 'Scanned'
          case (this.transaction.scanned):
            return 'Crawled'
          default:
            return 'Queued'
        }
      }
    }
  }
</script>
