<template>
    <v-container v-if="scan">
        <h1 class="mb-3 text-center">Scan of {{ scan.site.name }}</h1>

        <scan-overview :scan="scan" :refreshing="refreshing" :onRefreshClicked="onRefresh.bind(this)"
            :onStopScanClicked="onStopScan.bind(this)" />
        <transaction-list @next="handleNext" @prev="handlePrev" title="Transaction List" :transactionData="transactions" :refreshing="refreshing" :itemsPerPage="itemsPerPage" />
    </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import TransactionList from '@/components/transactions/TransactionList.vue'
import ScanOverview from '@/components/scans/ScanOverview.vue'

export default {
    title() {
        return this.scan && `Scan of ${this.scan.site.name}`
    },

    components: { ScanOverview, TransactionList },

    props: ['id'],

    data: () => ({
        refreshing: false,
        itemsPerPage: 100,
    }),

    computed: {
        ...mapGetters({
            scan: 'scans/scan',
            transactions: 'scans/transactions'
        })
    },

    created: function () {
        this.onRefreshTransactions()
    },

    beforeRouteEnter: async function (to, from, next) {
        await next(async vm => {
            vm.setLoading(true)
            await vm.getScanDetails(to.params.id)
            vm.setLoading(false)
        })
    },

    methods: {
        ...mapActions({
            setLoading: 'setLoading',
            getScanDetails: 'scans/getScanDetails',
            getScanTransactions: 'scans/getScanTransactions'
        }),
        onRefresh: async function () {
            if (!this.scan) return

            this.getScanDetails(this.scan.id)
            this.onRefreshTransactions()
        },

        onStopScan: async function () {
            if (!this.scan) return

            this.setLoading(true)
            await this.stopScan(this.scan.id)
            this.setLoading(false)
        },

        onRefreshTransactions: async function (page = 0, itemsPerPage = 50) {
            if (!this.scan) return

            this.refreshing = true
            await this.getScanTransactions({ id: this.scan.id, page, itemsPerPage })
            this.refreshing = false
        },

        stopScan: async function (scanId) {
            if (!scanId) return

            this.refreshing = true
            await this.$store.dispatch('scans/stopScan', scanId)
            window.location.reload()

        },
        
        handleNext: async function (e) {
            this.refreshing = true;
            await this.getScanTransactions({id: this.scan.id, page: e.page - 1, itemsPerPage: 100})
            this.refreshing = false;
        },

        handlePrev: async function (e) {
            this.refreshing = true;
            await this.getScanTransactions({id: this.scan.id, page: e.page - 1, itemsPerPage: 100})
            this.refreshing = false;
        }, 

    }
}
</script>
