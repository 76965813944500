<template>
    <v-card class="pa-4 mb-6">
        <v-card-title>
            Scan Overview
            <v-dialog v-model="showDeleteDialog" max-width="500">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn color="red" fab x-small class="ml-3" title="Delete scan" v-bind="attrs" v-on="on"
                        :disabled="scan.status === 'Running'"><v-icon>mdi-delete</v-icon></v-btn>
                </template>
                <v-card>
                    <v-card-title class="text-h5">
                        Delete scan
                    </v-card-title>
                    <v-card-text>
                        Are you sure you want to delete this scan?
                        <br><br>
                        <b>WARNING:</b> This action cannot be undone.</v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="grey" text @click="showDeleteDialog = false">
                            Cancel
                        </v-btn>
                        <v-btn color="error" :loading="deleting" @click="onDeleteScan">
                            Delete
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-spacer />
            <div class="d-flex flex-column" style="gap: 1rem">
                <refresh-button v-if="onRefreshClicked" :loading="refreshing" :onClick="onRefreshClicked" />
                <stop-scan-button v-if="scan.status === 'Running'" :loading="refreshing" :onClick="onStopScanClicked" />
            </div>
        </v-card-title>
        <v-card-text>
            <div>
                <span class="d-block">
                    <b>Scan Date: </b>
                    <span class="d-none d-md-inline">{{ moment(scan.timestamp).format('MMM D, YYYY - h:mm:ss a') }}</span>
                    <span class="d-md-none">{{ moment(scan.timestamp).format('M/D/YY - h:mm a') }}</span>
                </span>
                <span class="d-block"><b>Base URL:</b> <a :href="scan.baseUrl" target="_blank">{{ scan.baseUrl }}</a></span>
                <span class="d-block"><b>Status:</b> <scan-status :status="scan.status" /></span>
                <span class="d-block"><b>Crawl Depth:</b> {{ scan.depth }}</span>
                <span class="d-block"><b>Pages Scanned:</b> {{ scan.scannedCount }}/{{ scan.pageCount }}</span>
                <span class="d-block"><b>Scan Duration:</b> {{ duration }}</span>
            </div>
            <v-divider class="my-6" />
            <v-btn v-if="scan.options.accessibility && scan.status === 'Completed'" class="mb-5" color="primary"
                :to="{ name: 'scan-report', params: { id: scan.id } }">
                View Report
            </v-btn>

            <div>
                <v-btn v-if="scan.summaryPath" class="mr-2" color="secondary" small @click="onDownloadSummary">
                    <v-icon small class="mr-2">mdi-download</v-icon>
                    PDF Summary
                </v-btn>

                <v-btn v-if="scan.reportPath" class="mr-2" color="secondary" small @click="onDownloadReport">
                    <v-icon small class="mr-2">mdi-download</v-icon>
                    PDF Report
                </v-btn>

                <v-btn v-if="scan.inventoryPath" class="mr-2" color="secondary" small @click="onDownloadInventory">
                    <v-icon small class="mr-2">mdi-download</v-icon>
                    CSV Inventory
                </v-btn>

                <v-btn v-if="scan.resultsPath" class="mr-2" color="secondary" small @click="onDownloadData">
                    <v-icon small class="mr-2">mdi-download</v-icon>
                    JSON Data
                </v-btn>
            </div>
        </v-card-text>
    </v-card>
</template>

<script>
import { Storage } from 'aws-amplify'
import { mapActions } from 'vuex'
import ScanStatus from '@/components/scans/ScanStatus.vue'
import RefreshButton from '@/components/RefreshButton.vue'
import StopScanButton from '@/components/StopScanButton.vue'

export default {
    components: {
        RefreshButton,
        ScanStatus,
        StopScanButton,

    },

    props: [
        'scan',
        'refreshing',
        'onRefreshClicked',
        'onStopScanClicked',
    ],

    data: () => ({
        showDeleteDialog: false,
        deleting: false
    }),

    computed: {
        duration: function () {
            const duration = this.moment.duration(this.scan.duration ?? this.moment().diff(this.scan.timestamp))
            if(duration < 0) return ''
            let output = `${duration.seconds()}s`
            if (duration.minutes() > 0)
                output = `${duration.minutes()}m ` + output
            if (duration.hours() > 0)
                output = `${duration.hours()}h ` + output

            return output
        }
    },

    methods: {
        ...mapActions({
            setSnackbar: 'setSnackbar',
            deleteScan: 'scans/deleteScan'
        }),
        onDeleteScan: async function () {
            if (!this.deleting) {
                const siteId = this.scan.siteId

                // Delete the scan
                this.deleting = true
                await this.deleteScan(this.scan.id)

                // Show success snackbar
                this.setSnackbar({
                    message: `Scan deleted successfully!`
                })

                // Route back to the site details page
                this.$router.push({ name: 'site-details', params: { id: siteId } })
            }
        },
        downloadBlob(blob, filename) {
            const url = URL.createObjectURL(blob)
            const link = document.createElement('a')
            link.href = url
            link.download = filename
            const clickHandler = () => {
                setTimeout(() => {
                    URL.revokeObjectURL(url)
                    link.removeEventListener('click', clickHandler)
                }, 150)
            }
            link.addEventListener('click', clickHandler)
            link.click();
        },
        onDownloadInventory: async function () {
            const result = await Storage.get(this.scan.inventoryPath, { download: true })
            this.downloadBlob(result.Body, `${this.scan.id}-inventory.csv`)
        },
        onDownloadSummary: async function () {
            const result = await Storage.get(this.scan.summaryPath, { download: true })
            this.downloadBlob(result.Body, `${this.scan.id}-summary.pdf`)
        },
        onDownloadReport: async function () {
            const result = await Storage.get(this.scan.reportPath, { download: true })
            this.downloadBlob(result.Body, `${this.scan.id}-report.pdf`)
        },
        onDownloadData: async function () {
            const result = await Storage.get(this.scan.resultsPath, { download: true })
            this.downloadBlob(result.Body, `${this.scan.id}-data.json`)
        },
        pageCountText: function (scan) {
            if (scan.pageCount > 0) {
                return scan.pageCount
            } else {
                if (scan.status === 'Scheduled' || scan.status === 'Running') {
                    return 'Pending'
                } else {
                    return 'Unknown'
                }
            }
        }
    }
}
</script>
